export const MOBILE_MAX_WIDTH = 750;
export const DRUM_CARD_MAX_WIDTH = 270;

// regex
export const REG_LOGO = /(\.|\s)(\/m)\s*?$/gm;
export const REG_DOUBLE_DASHES = /([^-])-{2}([^-])/gm;
export const REG_TRIPLE_DASHES = /-{3}/gm;
export const REG_TRIPLE_DASHES_NO_BREAK = /([^ |] +)-{3}( +[^ |])/gm;

export const baseSlug = '[a-z0-9-_]+';

export const pulseBaseUrl = '/pulse';

export const filters = [
  {
    type: 'all',
    slug: 'all',
    title: 'Все',
  },
  {
    type: 'topic_type',
    slug: 'article',
    title: 'Статьи',
  },
  {
    type: 'topic_type',
    slug: 'news',
    title: 'Новости',
  },
  {
    type: 'rubric',
    slug: 'testdrives',
    title: 'Тест-драйвы',
  },
  {
    type: 'topic_type',
    slug: 'video',
    title: 'Видео',
  },
  {
    type: 'topic_type',
    slug: 'gallery',
    title: 'Фото',
  },
];

export const getUtm = (breakpoint) => (`?utm_source=read_more&utm_medium=${breakpoint}&utm_campaign=Research_read_more_traffic`);
